import { assertInteger, castInteger, isInteger } from './integer'
import type { Integer } from './integer'
import type { DateTime } from '../interfaces/date-time'

export type UnixTimestampSeconds = string & Integer & { __iq_type_unix_timestamp: never }

export function isUnixTimestampSeconds(
  value: UnixTimestampSeconds | string | unknown
): value is UnixTimestampSeconds {
  return isInteger(value)
}

export function getUnixTimestampSecondsNow(offset: number = 0): UnixTimestampSeconds {
  assertInteger(offset)
  return castInteger(Date.now() / 1000 + offset) as UnixTimestampSeconds
}

export function getUnixTimestampSecondsByLuxon(date: DateTime): UnixTimestampSeconds {
  return castInteger(date.toSeconds()) as UnixTimestampSeconds
}

export function assertUnixTimestampSeconds(
  value: UnixTimestampSeconds | string | unknown
): asserts value is UnixTimestampSeconds {
  if (!isUnixTimestampSeconds(value)) {
    assertInteger(value)
    throw new Error("Expected uuid string, got '" + value + "'.")
  }
}

export function validUnixTimestamp(
  value: UnixTimestampSeconds | string | unknown
): UnixTimestampSeconds {
  assertUnixTimestampSeconds(value)
  return value
}
