export function isDefined<T>(value: T | undefined): value is T {
  return typeof value !== 'undefined'
}

export function assertDefined<T>(value: T | undefined): asserts value is T {
  if (!isDefined<T>(value)) {
    throw new Error('Expected defined, got undefined.')
  }
}

export function validDefined<T>(value: T | undefined): T {
  assertDefined<T>(value)
  return value
}
