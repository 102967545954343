import { assertString } from './string'

export type StringNotEmpty = string & { __iq_type_string_not_empty: never }

export function isStringNotEmpty(
  value: StringNotEmpty | string | unknown
): value is StringNotEmpty {
  return typeof value === 'string' && value !== ''
}

export function assertStringNotEmpty(
  value: StringNotEmpty | string | unknown
): asserts value is StringNotEmpty {
  if (!isStringNotEmpty(value)) {
    assertString(value)
    throw new Error("Expected not empty string, got '" + value + "'.")
  }
}

export function validStringNotEmpty(value: StringNotEmpty | string | unknown): StringNotEmpty {
  assertStringNotEmpty(value)
  return value
}
