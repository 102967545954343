import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";
import { AuthUserStoreService } from "../../_store/auth-user-store.service";
import { SUPPORTED_TRANSLATION_LOCALES } from "../translations.service";

@Injectable({
  providedIn: "root",
})
export class LocaleRepositoryService {
  private _fallbackLocale: "de" | "en" | null;
  private _observable: Observable<string | null>;

  constructor(private _authUserStoreService: AuthUserStoreService) {
    this._fallbackLocale =
      SUPPORTED_TRANSLATION_LOCALES.find((l) =>
        navigator.language.includes(l)
      ) ?? null;
    this._observable = this._authUserStoreService.observable
      .pipe(map((user) => user?.user.language ?? this._fallbackLocale))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));
  }

  public get observable(): Observable<string | null> {
    return this._observable;
  }
}
