import { DatasourceType } from "src/app/_models/datasource-type";
import { validDefined } from "src/../../src/types/defined";
import {
  StringNotEmpty,
  validStringNotEmpty,
} from "src/../../src/types/string-not-empty";

export abstract class FieldAlias {
  private static _nameFromAlias(str: string): string {
    if (!str.startsWith('"') || !str.endsWith('"')) {
      return str;
    }
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  abstract isNative(): this is NativeFieldAlias;
  isCustom(): this is CustomFieldAlias {
    return !this.isNative();
  }
  abstract isDimension(): boolean;
  isMetric(): boolean {
    return !this.isDimension();
  }
  abstract readonly datasourceType: DatasourceType | null;
  abstract readonly name: string;

  public static createNativeDimension(
    datasourceType: DatasourceType,
    name: string
  ): FieldAlias {
    return new NativeFieldAlias("nd", datasourceType, name);
  }

  public static createNativeMetric(
    datasourceType: DatasourceType,
    name: string
  ): FieldAlias {
    return new NativeFieldAlias("nm", datasourceType, name);
  }

  public static createCustomDimension(name: string): FieldAlias {
    return new CustomFieldAlias("cd", name);
  }

  public static createCustomMetric(name: string): FieldAlias {
    return new CustomFieldAlias("cm", name);
  }

  public static createFromString(alias: StringNotEmpty): FieldAlias {
    const SEPARATOR = ":";
    const parts = alias.split(SEPARATOR);
    try {
      const type = parts[0];
      switch (type) {
        case "nd":
          return FieldAlias.createNativeDimension(
            validDefined(parts[1]) as DatasourceType, //TODO validate  as DatasourceType
            FieldAlias._nameFromAlias(parts.slice(2).join(SEPARATOR))
          );
        case "nm":
          return FieldAlias.createNativeMetric(
            validDefined(parts[1]) as DatasourceType, //TODO validate  as DatasourceType
            FieldAlias._nameFromAlias(parts.slice(2).join(SEPARATOR))
          );
        case "cd":
          return FieldAlias.createCustomDimension(
            FieldAlias._nameFromAlias(parts.slice(1).join(SEPARATOR))
          );
        case "cm":
          return FieldAlias.createCustomMetric(
            FieldAlias._nameFromAlias(parts.slice(1).join(SEPARATOR))
          );
      }
    } catch (e) {
      console.debug("FieldAlias: Unsupported field alias", alias, e);
      throw new Error("Unsupported field alias " + alias);
    }

    console.debug("FieldAlias: Unsupported field alias", alias);
    throw new Error("Unsupported field alias " + alias);
  }

  public static isFieldAlias(alias: string): alias is StringNotEmpty {
    const SEPARATOR = ":";
    const parts = alias.split(SEPARATOR, 1);
    const type = validDefined(parts[0]);
    switch (type) {
      case "nd":
      case "nm":
      case "cd":
      case "cm":
        return true;
    }

    return false;
  }

  public abstract toString(): StringNotEmpty;

  public abstract equals(other: FieldAlias): boolean;
}

export class NativeFieldAlias extends FieldAlias {
  private readonly _str: StringNotEmpty;
  public constructor(
    public readonly type: "nd" | "nm",
    public readonly datasourceType: DatasourceType,
    public readonly name: string
  ) {
    super();
    this._str = this._toString();
  }

  isNative(): this is NativeFieldAlias {
    return true;
  }
  isDimension(): boolean {
    return this.type === "nd";
  }

  private _toString(): StringNotEmpty {
    return validStringNotEmpty(
      [
        this.type,
        this.datasourceType,
        /^[a-zA-Z0-9_.]+$/.test(this.name)
          ? this.name
          : JSON.stringify(this.name),
      ].join(":")
    );
  }

  public toString(): StringNotEmpty {
    return this._str;
  }

  public equals(other: FieldAlias): boolean {
    return (
      other instanceof NativeFieldAlias &&
      other.type === this.type &&
      other.datasourceType === this.datasourceType &&
      other.name === this.name
    );
  }
}

export class CustomFieldAlias extends FieldAlias {
  private readonly _str: StringNotEmpty;
  public constructor(
    public readonly type: "cd" | "cm",
    public readonly name: string
  ) {
    super();
    this._str = this._toString();
  }

  isNative(): this is NativeFieldAlias {
    return false;
  }
  isDimension(): boolean {
    return this.type === "cd";
  }
  get datasourceType(): null {
    return null;
  }

  private _toString(): StringNotEmpty {
    return validStringNotEmpty(
      [
        this.type,
        /^[a-zA-Z0-9_.]+$/.test(this.name)
          ? this.name
          : JSON.stringify(this.name),
      ].join(":")
    );
  }

  public toString(): StringNotEmpty {
    return this._str;
  }

  public equals(other: FieldAlias): boolean {
    return (
      other instanceof CustomFieldAlias &&
      other.type === this.type &&
      other.name === this.name
    );
  }
}

export const FIELD_ALIAS_IQ_DATE = FieldAlias.createFromString(
  "nd:iq:date" as StringNotEmpty
);
export const FIELD_ALIAS_IQ_SOURCE_DATASOURCE_TYPE =
  FieldAlias.createFromString(
    "nd:iq:source.data_source.type" as StringNotEmpty
  );
export const FIELD_ALIAS_IQ_SOURCE_DATASOURCE_TEMPLATE_ID =
  FieldAlias.createFromString(
    "nd:iq:source.data_source.template.id" as StringNotEmpty
  );
