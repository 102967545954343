import { Injectable } from "@angular/core";
import { ServerService } from "../http/server.service";
import { CurrentTenantService } from "../current-tenant.service";
import { HostsService } from "../hosts.service";
import { Observable } from "rxjs";
import { TenantResponse } from "src/app/_models/tenant-response";
import { map } from "rxjs/operators";
import { Scope } from "src/app/_models/scope";
import {
  assertTenantId,
  TenantId,
  validTenantId,
} from "src/../../src/types/tenant-id";
import { assertUuid, Uuid } from "src/../../src/types/uuid";
import { AccessToken } from "../../_models/access-token";
import { Email } from "src/../../src/types/email";

export interface MeResponse {
  tenantId: TenantId | null;
  tenantName: string | null;
  scopes: string[];
  username: string;
  language: string | null;
  client: {
    id: string;
    name: string;
    scopes: string[];
  };
  user:
    | null
    | User
    | {
        id: string | null;
        email: string | null;
        firstname?: string;
        lastname?: string;
        fullname?: string;
        language?: string | null;
        scopes?: string[];
      };
}

export interface SecuritySubscriptionPublic {
  id: Uuid;
  name: string;
  tenants: TenantId[];
}

export interface SecuritySubscription extends SecuritySubscriptionPublic {
  id: Uuid;
  name: string;
  active: boolean;
  maxTenants: number;
  maxUsers: number;
  owners: User[];
  tenants: TenantId[];
  createdAt: Date;
  deactivateAt: Date;
}

export interface SecuritySubscriptionWithUserCount
  extends SecuritySubscription {
  userCount: number;
}

export type SecuritySubscriptionMayWithUserCount =
  | SecuritySubscription
  | SecuritySubscriptionWithUserCount;

export interface SecurityUserSubscription extends SecuritySubscription {
  userIsOwner: boolean;
}

export interface RefreshToken {
  id: Uuid;
  userId: Uuid | null;
  clientId: Uuid | null;
  limitedTenantId: TenantId | null;
  limitedScopes: string[] | null;
  createdAt: number;
  expiresAt: number;
  lastUsedAt: number;
  userAgent: string | null;
  tokenMd5: string;
}

export interface OauthClient {
  id: string;
  name: string;
}

export interface AuthTokenResponse {
  access_token: string;
  refresh_token: string | null;
  token_type: string;
  expires_in: number;
  scope: string | null;
}

export interface Group {
  id: Uuid;
  tenantId: TenantId;
  active: boolean;
  name: string;
  description: string | null;
  scopes: string[];
}

export interface User {
  id: Uuid;
  email: Email;
  language: string;
  firstname: string | null;
  lastname: string | null;
  fullname: string | null;
  scopes: string[];
}

export interface UserWithGroups extends User {
  groups: Group[];
}

export interface UserWithSubscriptions extends UserWithGroups {
  subscriptions: SecurityUserSubscription[];
}

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  private readonly host: string;

  constructor(
    private _server: ServerService,
    private _currentTenantService: CurrentTenantService,
    private _hostsService: HostsService
  ) {
    this.host = this._hostsService.securityHost;
  }

  private _tenantResponse<T>(
    tenantId: TenantId
  ): (data: T) => TenantResponse<T> {
    return (data) => new TenantResponse(tenantId, data);
  }

  public getMe(tenantId?: string): Observable<MeResponse> {
    if (typeof tenantId === "undefined") {
      return this._server.request<MeResponse>("GET", this.host + "/auth/me");
    }

    assertTenantId(tenantId);

    return this._server.requestWithTenantId<MeResponse>(
      "GET",
      this.host + "/auth/me",
      tenantId
    );
  }

  public updateMe(data: {
    lastname?: string;
    firstname?: string;
    language?: string;
  }): Observable<UserWithGroups> {
    return this._server.request<UserWithGroups>(
      "POST",
      this.host + "/users/me",
      data
    );
  }

  public getAccessTokenFromUserPassword(
    tenantId: TenantId | null,
    username: string,
    password: string
  ): Observable<{
    accessToken: AccessToken;
    refreshToken: string | null;
  }> {
    const start = parseInt((Date.now() / 1000).toString());
    return this._server
      .requestUnauthorizedWithTenantId<AuthTokenResponse>(
        "POST",
        this.host + "/auth/token",
        tenantId,
        {
          grant_type: "password",
          username: username,
          password: password,
        }
      )
      .pipe(
        map((response) => ({
          accessToken: new AccessToken(
            response.access_token,
            start + response.expires_in
          ),
          refreshToken: response.refresh_token ?? null,
        }))
      );
  }

  public getAccessTokenFromRefreshToken(
    tenantId: TenantId | null,
    refreshToken: string
  ): Observable<{
    accessToken: AccessToken;
    refreshToken: string | null;
  }> {
    const start = parseInt((Date.now() / 1000).toString());
    return this._server
      .requestUnauthorizedWithTenantId<AuthTokenResponse>(
        "POST",
        this.host + "/auth/token",
        tenantId,
        {
          grant_type: "refresh_token",
          refresh_token: refreshToken,
        }
      )
      .pipe(
        map((response) => ({
          accessToken: new AccessToken(
            response.access_token,
            start + response.expires_in
          ),
          refreshToken: response.refresh_token ?? null,
        }))
      );
  }
  public getAuthCodeRedirectUriForClient(
    tenantId: TenantId | null,
    clientId: string,
    scopes: Scope[],
    redirectUri: string,
    state: string | undefined
  ): Observable<string> {
    return this._server
      .request<{ redirect_uri?: string } | null>(
        "POST",
        this.host +
          "/auth/code" +
          (tenantId !== null ? "?tenant_id=" + tenantId : ""),
        {
          client_id: clientId,
          scope: scopes.map((s) => s.toString()).join(" "),
          redirect_uri: redirectUri,
          state: state,
        }
      )
      .pipe(
        map((response) => {
          const redirectUriWithParams = response && response.redirect_uri;
          if (typeof redirectUriWithParams !== "string") {
            throw new Error(
              "Failed to fetch redirect_uri with params from /auth/code"
            );
          }
          return redirectUriWithParams;
        })
      );
  }

  public getMyRefreshTokens(
    tenantId?: string
  ): Observable<TenantResponse<RefreshToken[]>> {
    const safeTenantId = validTenantId(
      tenantId ?? this._currentTenantService.currentTenantId
    );

    return this._server
      .requestWithTenantId<RefreshToken[]>(
        "GET",
        this.host + "/refresh_tokens/my",
        safeTenantId
      )
      .pipe(map(this._tenantResponse(safeTenantId)));
  }

  public deleteMyRefreshTokenById(
    refreshTokenId: Uuid
  ): Observable<{ deleted: 0 | 1 }> {
    return this._server.requestWithTenantId<{ deleted: 0 | 1 }>(
      "DELETE",
      this.host + "/refresh_tokens/my/" + encodeURIComponent(refreshTokenId),
      null
    );
  }

  public deleteMyRefreshTokenByToken(
    refreshToken: string
  ): Observable<{ deleted: 0 | 1 }> {
    return this._server.requestWithTenantId<{ deleted: 0 | 1 }>(
      "DELETE",
      this.host + "/refresh_tokens/my",
      null,
      {
        token: refreshToken,
      }
    );
  }

  public getOauthClientById(
    oauthClientId: string,
    tenantId?: string
  ): Observable<TenantResponse<OauthClient>> {
    const safeTenantId = validTenantId(
      tenantId ?? this._currentTenantService.currentTenantId
    );

    return this._server
      .requestWithTenantId<OauthClient>(
        "GET",
        this.host + "/oauth_client/" + encodeURIComponent(oauthClientId),
        safeTenantId
      )
      .pipe(map(this._tenantResponse(safeTenantId)));
  }

  public getOauthClients(
    tenantId?: string
  ): Observable<TenantResponse<OauthClient[]>> {
    const safeTenantId = validTenantId(
      tenantId ?? this._currentTenantService.currentTenantId
    );

    return this._server
      .requestWithTenantId<OauthClient[]>(
        "GET",
        this.host + "/oauth_client",
        safeTenantId
      )
      .pipe(map(this._tenantResponse(safeTenantId)));
  }

  public createUser(
    data: {
      email: string;
      firstname?: string | null;
      lastname?: string | null;
      language?: string | null;
      groups: [string];
    },
    tenantId?: string
  ): Observable<TenantResponse<UserWithGroups>> {
    const safeTenantId = validTenantId(
      tenantId ?? this._currentTenantService.currentTenantId
    );

    return this._server
      .requestWithTenantId<UserWithGroups>(
        "POST",
        this.host + "/users",
        safeTenantId,
        data
      )
      .pipe(map(this._tenantResponse(safeTenantId)));
  }

  public editUser(
    data: {
      firstname?: string | null;
      lastname?: string | null;
      language?: string | null;
      groups: [string];
    },
    userId: string,
    tenantId?: string
  ): Observable<TenantResponse<UserWithGroups>> {
    const safeTenantId = validTenantId(
      tenantId ?? this._currentTenantService.currentTenantId
    );

    return this._server
      .requestWithTenantId<UserWithGroups>(
        "POST",
        this.host + "/users/" + userId,
        safeTenantId,
        data
      )
      .pipe(map(this._tenantResponse(safeTenantId)));
  }

  public removeUserForTenant(
    userId: string,
    tenantId: TenantId
  ): Observable<void> {
    const safeTenantId = validTenantId(tenantId);

    return this._server.requestWithTenantId<void>(
      "DELETE",
      this.host + "/users/" + userId,
      safeTenantId
    );
  }

  public removeUserForTenants(
    userId: string,
    tenantIds: TenantId[]
  ): Observable<void> {
    return this._server.requestWithTenantId<void>(
      "DELETE",
      this.host + "/users/" + userId,
      tenantIds
    );
  }

  public removeUserForAllTenants(userId: string): Observable<void> {
    return this._server.request<void>("DELETE", this.host + "/users/" + userId);
  }

  public getCommonTenantIds(userId: string): Observable<string[]> {
    assertUuid(userId);

    return this._server
      .request<string[]>("GET", this.host + "/tenants/get_by_user_id/" + userId)
      .pipe(map((response) => response));
  }

  public getUsers(
    tenantId?: string
  ): Observable<TenantResponse<UserWithGroups[]>> {
    const safeTenantId = validTenantId(
      tenantId ?? this._currentTenantService.currentTenantId
    );

    return this._server
      .requestWithTenantId<UserWithGroups[]>(
        "GET",
        this.host + "/users",
        safeTenantId
      )
      .pipe(map(this._tenantResponse(safeTenantId)));
  }

  public getUserMe(
    accessToken: AccessToken
  ): Observable<UserWithSubscriptions> {
    return this._server.requestWithAccessToken<UserWithSubscriptions>(
      "GET",
      this.host + "/users/me",
      accessToken
    );
  }

  public getGroups(tenantId?: string): Observable<TenantResponse<Group[]>> {
    const safeTenantId = validTenantId(
      tenantId ?? this._currentTenantService.currentTenantId
    );

    return this._server
      .requestWithTenantId<Group[]>("GET", this.host + "/groups", safeTenantId)
      .pipe(map(this._tenantResponse(safeTenantId)));
  }

  public passwordResetEmail(email: string): Observable<void> {
    return this._server.requestUnauthorized(
      "POST",
      this.host + "/password_reset/send_email",
      {
        email: email,
      }
    );
  }

  public checkOObCode(oobCode: string): Observable<void> {
    return this._server.requestUnauthorized(
      "POST",
      this.host + "/password_reset/check_oob_code",
      {
        oobCode: oobCode,
      }
    );
  }

  public resetPassword(oobCode: string, newPassword: string): Observable<void> {
    return this._server.requestUnauthorized(
      "POST",
      this.host + "/password_reset/reset_password",
      {
        oobCode: oobCode,
        newPassword: newPassword,
      }
    );
  }

  public getSubscriptions(): Observable<SecuritySubscriptionWithUserCount[]> {
    return this._server.request<SecuritySubscriptionWithUserCount[]>(
      "GET",
      this.host + "/subscriptions" + "?with_user_count=true"
    );
  }

  public getMySubscriptions(): Observable<SecuritySubscriptionPublic[]> {
    return this._server.request<SecuritySubscriptionPublic[]>(
      "GET",
      this.host + "/subscriptions/my"
    );
  }

  public createSubscription(data: {
    name?: string;
    active?: boolean;
    maxTenants?: number;
    maxUsers?: number;
    owners?: string[];
  }): Observable<SecuritySubscriptionWithUserCount> {
    return this._server.request<SecuritySubscriptionWithUserCount>(
      "POST",
      this.host + "/subscriptions" + "?with_user_count=true",
      data
    );
  }

  public removeSubscription(
    subscriptionsId: string
  ): Observable<SecuritySubscriptionWithUserCount> {
    return this._server.request<SecuritySubscriptionWithUserCount>(
      "DELETE",
      this.host + "/subscriptions/" + subscriptionsId + "?with_user_count=true"
    );
  }

  public deactivateSubscription(
    subscriptionsId: string
  ): Observable<SecuritySubscriptionWithUserCount> {
    return this._server.request<SecuritySubscriptionWithUserCount>(
      "POST",
      this.host +
        "/subscriptions/" +
        subscriptionsId +
        "/deactivate" +
        "?with_user_count=true"
    );
  }

  public activateSubscription(
    subscriptionsId: string
  ): Observable<SecuritySubscriptionWithUserCount> {
    return this._server.request<SecuritySubscriptionWithUserCount>(
      "POST",
      this.host +
        "/subscriptions/" +
        subscriptionsId +
        "/activate" +
        "?with_user_count=true"
    );
  }

  public updateSubscription(
    subscriptionId: string,
    data: {
      name?: string;
      active?: boolean;
      maxTenants?: number;
      maxUsers?: number;
      owners?: string[];
      deactivateAt?: number;
    }
  ): Observable<SecuritySubscriptionWithUserCount> {
    return this._server.request<SecuritySubscriptionWithUserCount>(
      "POST",
      this.host + "/subscriptions/" + subscriptionId + "?with_user_count=true",
      data
    );
  }

  public impersonate(userId: string): Observable<{
    accessToken: AccessToken;
    refreshToken: string | null;
  }> {
    const start = parseInt((Date.now() / 1000).toString());
    return this._server
      .request<AuthTokenResponse>(
        "POST",
        this.host + "/auth/impersonate/" + userId
      )
      .pipe(
        map((response) => ({
          accessToken: new AccessToken(
            response.access_token,
            start + response.expires_in
          ),
          refreshToken: response.refresh_token ?? null,
        }))
      );
  }
}
