import { Scope, Scopes } from "./scope";
import { validDefined } from "src/../../src/types/defined";
import { TenantId } from "src/../../src/types/tenant-id";
import { UserWithSubscriptions } from "../_services/api/security.service";
import { UtilService } from "../_services/util.service";
import { Uuid } from "src/../../src/types/uuid";

export class IqUser {
  constructor(
    public readonly userId: Uuid,
    public readonly userEmail: string | null,
    public readonly userFirstName: string | null,
    public readonly userLastName: string | null,
    public readonly adminScopes: Scope[],
    public readonly language: string | null,
    private readonly _ownerOfSubscriptionIds: string[],
    private readonly _scopesByTenant: { [tenantId: TenantId]: Scope[] }
  ) {}

  public get userFullName(): string | null {
    return (this.userFirstName + " " + this.userLastName).trim() || null;
  }

  public get ownerOfSubscriptionIds(): string[] {
    return this._ownerOfSubscriptionIds.map((id) => id);
  }

  public getTenantIds(): TenantId[] {
    return Object.keys(this._scopesByTenant) as TenantId[];
  }

  public canAnyForTenant(tenantId: TenantId): boolean {
    if (
      this.adminScopes.length > 0 &&
      this.adminScopes.some((scope) => scope.allows(Scopes.TENANT_INDEPENDENT))
    ) {
      return true;
    }

    return (
      this._scopesByTenant.hasOwnProperty(tenantId) &&
      (this._scopesByTenant[tenantId] ?? []).length > 0
    );
  }

  private getScopesByTenant(tenantId: TenantId | null): Scope[] {
    const scopes: Scope[] = tenantId
      ? this._scopesByTenant[tenantId] ?? []
      : [];
    if (
      this.adminScopes.some((scope) => scope.allows(Scopes.TENANT_INDEPENDENT))
    ) {
      this.adminScopes.forEach((scope) => scopes.push(scope));
    }
    return scopes;
  }
  scopesByTenant;

  public static fromUser(user: UserWithSubscriptions): IqUser {
    const scopesByTenant: {
      [tenantId: string]: Scope[];
    } = {};
    user.groups.forEach((group) => {
      scopesByTenant[group.tenantId] = UtilService.arrayUniqueStr([
        ...(scopesByTenant[group.tenantId] ?? []).map((scope) =>
          scope.toString()
        ),
        ...group.scopes,
      ]).map((scope) => Scope.fromString(scope));
    });
    return new IqUser(
      user.id,
      user.email,
      user.firstname,
      user.lastname,
      user.scopes.map((scope) => Scope.fromString(scope)),
      user.language,
      user.subscriptions
        .filter((subscribtion) => subscribtion.userIsOwner)
        .map((subscription) => subscription.id),
      scopesByTenant
    );
  }

  public isAdmin(): boolean {
    return this.userCanAllForTenant(
      [Scopes.TENANT_INDEPENDENT, Scopes.ALL],
      null
    );
  }

  public userCanForTenant(scope: Scope, tenantId: TenantId | null): boolean {
    const scopes = this.getScopesByTenant(tenantId);

    for (let i = 0; i < scopes.length; i++) {
      const userScope = validDefined(scopes[i]);
      if (userScope.allows(scope)) {
        return true;
      }
    }

    return false;
  }

  public userCanForAnyTenant(scope: Scope): boolean {
    return (
      this.userCanForTenant(scope, null) ||
      this.getTenantIds().some((tenantId) =>
        this.userCanForTenant(scope, tenantId)
      )
    );
  }

  public userCanAllForTenant(
    scopes: Scope[],
    tenantId: TenantId | null
  ): boolean {
    for (let i = 0; i < scopes.length; i++) {
      if (!this.userCanForTenant(validDefined(scopes[i]), tenantId)) {
        return false;
      }
    }

    return true;
  }

  public isSubscriptionOwnerOf(subscriptionId: string): boolean {
    for (let i = 0; i < this.ownerOfSubscriptionIds.length; i++) {
      if (subscriptionId === this.ownerOfSubscriptionIds[i]) {
        return true;
      }
    }

    return false;
  }

  public isSubscriptionOwnerOfAny(): boolean {
    return this.ownerOfSubscriptionIds.length > 0;
  }
}
